<template>
  <div>
    <el-breadcrumb separator=">">
      <el-breadcrumb-item :to="{ path: '/' }">首页</el-breadcrumb-item>
      <el-breadcrumb-item>
        <a href="#">系统管理</a>
      </el-breadcrumb-item>
      <el-breadcrumb-item>
        <a href="/parkingRecord">停车收费记录</a>
      </el-breadcrumb-item>
    </el-breadcrumb>
    <el-divider></el-divider>
    <!--
      要resetFields起作用，必须配置:model和prop
    -->
    <el-form
      ref="queryForm"
      :model="queryModel"
      inline
      class="demo-form-inline"
    >
      <el-form-item label="车牌号码" prop="carNum">
        <el-input
          type="text"
          size="mini"
          v-model="queryModel.carNum"
        ></el-input>
      </el-form-item>
      <el-form-item label="所属物业公司" prop="companyId">
        <el-select-tree
          :options="companyResult"
          v-model="queryModel.companyId"
          height="200"
          style="width: 230px"
          size="mini"
        ></el-select-tree>
      </el-form-item>
      <el-form-item label="车辆类型" prop="carType">
        <el-select
          filterable
          placeholder="请选择"
          style="width: 150px"
          v-model="queryModel.carType"
          size="mini"
        >
          <el-option
            v-for="result in carTypeList"
            :key="result.value"
            :label="result.name"
            :value="result.value"
          ></el-option>
        </el-select>
      </el-form-item>
    <el-form-item label="支付方式" prop="payType">
        <el-select
          filterable
          placeholder="请选择"
          style="width: 150px"
          v-model="queryModel.payType"
          size="mini"
        >
          <el-option
            v-for="result in payTypeList"
            :key="result.value"
            :label="result.name"
            :value="result.value"
          ></el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="缴费时间">
        <el-date-picker
            v-model="queryModel.paymentTimeRange"
            type="daterange"
            size="mini"
            range-separator="至"
            start-placeholder="开始时间"
            end-placeholder="结束时间"
            placeholder="选择时间范围"
            value-format="yyyy-MM-dd"
            >
        </el-date-picker>
      </el-form-item>
      <el-form-item>
        <el-button
          type="primary"
          size="mini"
          icon="ios-search"
          @click="changePage(1)"
          :loading="loading"
          >查询</el-button
        >&nbsp;
        <el-button
          type="info"
          size="mini"
          style="margin-left: 8px"
          @click="handleReset('queryForm')"
          >重置</el-button
        >&nbsp;
      </el-form-item>
    </el-form>
    <el-divider></el-divider>
    <el-row class="button-group">
      <el-button type="success" size="small" plain icon="el-icon-download" @click="exportXls()">批量导出</el-button>
    </el-row>
    <el-table
      :data="tableData"
      style="min-height: 400px"
      v-loading="loading"
      stripe
      @sort-change="sortChange"
      @selection-change="handleSelectionChange"
    >
      <el-table-column
        prop="carNum"
        label="车牌号码"
      ></el-table-column>
      <el-table-column
        prop="companyName"
        label="所属物业"
      ></el-table-column>
      <el-table-column
        prop="carType"
        label="车辆类型"
      ></el-table-column>
      <el-table-column
        prop="parkingTime"
        label="累计时长（分钟）"
      ></el-table-column>
      <el-table-column
        prop="totalAmount"
        label="应收费用（元）"
      ></el-table-column>
      <el-table-column
        prop="reduceAmount"
        label="优惠金额（元）"
      ></el-table-column>
      <el-table-column
        prop="payAmount"
        label="实收费用（元）"
      ></el-table-column>
      <el-table-column
        prop="paymentTime"
        label="缴费时间"
      ></el-table-column>
        <el-table-column
        prop="payType"
        label="支付方式"
      ></el-table-column>
    </el-table>
    <el-pagination
      :current-page.sync="pageIndex"
      :total="totalElements"
      :page-sizes="pageSizeList"
      @current-change="changePage"
      @size-change="pageSizeChange"
      layout="total, sizes, prev, pager, next, jumper"
    ></el-pagination>
  </div>
</template>
<script>
import parkingRecordApi from "@/api/base/parkingRecord";
import companyInfoApi from "@/api/base/companyInfo";
import SelectTree from "@/components/SelectTree";
import "nprogress/nprogress.css"; // progress bar style

export default {
  name: "baseParkingRecordList",
  data() {
    var self = this;

    return {
      queryModel: {
        carNum: "",
        companyId: "",
        carType: "",
        payType: "",
        paymentTimeRange: ['','']
      },
      loading: false,
      tableData: [],
      pageIndex: 1,
      pageSize: 10,
      totalPages: 0,
      totalElements: 0,
      field: "",
      direction: "",
      pageSizeList: [10, 20, 30],
      multipleSelection: [],
      showModal: false,
      modalTitle: "",
      businessKey: "",
      typeResult: [],
      companyResult: [],
      carTypeList:[],
      payTypeList:[
        {name:"微信",
        value:"wechat"},
        {name:"支付宝",
        value:"alipay"},
        {name:"现金",
        value:"cash"}
      ]
    };
  },
  created() {
    var self = this;

    companyInfoApi.treeList().then(function (response) {
      var jsonData = response.data;
      if (jsonData.result) {
        if (jsonData.data != null && jsonData.data != "") {
          self.companyResult = jsonData.data;
        }
      }
    });

    parkingRecordApi.carTypeList().then(function (response) {
      var jsonData = response.data;
      if (jsonData.result) {
        if (jsonData.data != null && jsonData.data != "") {
          self.carTypeList = jsonData.data;
        }
      }
    });
  },
  methods: {
    changePage(pageIndex,exportFlag) {
      var self = this;

      self.loading = true;

      self.pageIndex = pageIndex;
      var formData = new FormData();

      formData.append("pageIndex", self.pageIndex);

      formData.append("carNum", self.queryModel.carNum);
      if(self.queryModel.companyId!=null&&self.queryModel.companyId!=""){
        formData.append("companyId", self.queryModel.companyId);
      }
      else{
          formData.append("companyId", "");
      }
      
      formData.append("carType", self.queryModel.carType);
      formData.append("payType", self.queryModel.payType);

      if(self.queryModel.paymentTimeRange[0] != null){
        formData.append("paymentStartTime", self.queryModel.paymentTimeRange[0]);
      }

      if(self.queryModel.paymentTimeRange[1] != null){
        formData.append("paymentEndTime", self.queryModel.paymentTimeRange[1]);
      }

      if(exportFlag!=null){
        formData.append("exportFlag", exportFlag);
        formData.append("pageSize", 10000);
      }
      else{
        formData.append("pageSize", self.pageSize);
      }

      parkingRecordApi
        .pageList(formData)
        .then(function (response) {
          self.loading = false;

          var jsonData = response.data;

          // self.tableData = jsonData.data;
          // self.totalPages = jsonData.totalPages;
          // self.totalElements = jsonData.recordsTotal;

          if(exportFlag){
            if(document.location.href.startsWith("https://")){
                jsonData.data = jsonData.data.replace("http://","https://");
            }
            
            //导出
            self.$message({
              showClose: true,
              type: "success",
              message: `报表已生成，<a href="${jsonData.data}">请点击链接下载</a>`,
              dangerouslyUseHTMLString: true,
              duration: 30000
            });
          }
          else{
            //分页查看
            var page = jsonData.data;

            self.tableData = page.data;
            self.totalPages = page.totalPages;
            self.totalElements = page.recordsTotal;
          }
        })
        .catch((error) => {
          self.loading = false;
          // self.$message.error(error + "");
        });
    },
    pageSizeChange(pageSize) {
      this.pageSize = pageSize;

      this.$nextTick(() => {
        this.changePage(this.pageIndex);
      });
    },
    sortChange(data) {
      this.field = data.column.field;
      this.direction = data.order;

      this.changePage(this.pageIndex);
    },
    handleSelectionChange(val) {
      this.multipleSelection = val;
    },
    handleReset(name) {
      this.$refs[name].resetFields();
      this.queryModel.paymentTimeRange = ['','']
    },
    exportXls() {
      this.changePage(1,true);
    }
  },
  mounted: function () {
    this.changePage(1);
  },
  components: {
    "el-select-tree": SelectTree,
  },
};
</script>
<style lang="scss" scoped>
.el-breadcrumb {
  margin: 10px;
  line-height: 20px;
}

.el-divider {
  margin: 5px 0;
}

.demo-form-inline {
  margin-left: 10px;
  text-align: left;
}

.button-group {
  margin-left: 10px;
  text-align: left;
}
</style>